// composables/useIdleTimer.ts
import { onMounted, onUnmounted } from "vue";

export function useIdleTimer(
  timeout = 5 * 60 * 1000, // 5 minutes
  onIdle: () => void,
) {
  let timeoutId: ReturnType<typeof setTimeout>;

  const resetTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onIdle();
    }, timeout);
  };

  const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

  const start = () => {
    resetTimer();
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer),
    );
  };

  const stop = () => {
    clearTimeout(timeoutId);
    activityEvents.forEach((event) =>
      window.removeEventListener(event, resetTimer),
    );
  };

  onMounted(start);
  onUnmounted(stop);

  return { start, stop };
}
